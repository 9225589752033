import { INavData } from "@coreui/angular";

export const navItems: INavData[] = [
  {
    name: "Unified Ads",
    url: "/dashboard",
    icon: "/assets/icons/sidebar/dashboard.svg",
  },
  {
    name: "Performance",
    url: "/performance",
    icon: "/assets/icons/sidebar/compare.svg",
  },
  {
    name: "Analytics Hub",
    url: "/hub",
    icon: "/assets/icons/sidebar/reports.svg",
  },
  {
    name: "Integrations",
    url: "/integrations",
    icon: "/assets/icons/sidebar/players.svg",
  },
  {
    name: "Demand Partners",
    url: "/demand-partners",
    icon: "/assets/icons/sidebar/filter.svg",
  },
  {
    name: "Automated Throttling",
    url: "/automated-throttling",
    icon: "/assets/icons/sidebar/demands.svg",
  },
  {
    name: "Alarms",
    url: "/alarms",
    icon: "/assets/icons/sidebar/alarms.svg",
  },
  {
    name: "App-Ads.txt Manager",
    url: "/app-ads",
    icon: "/assets/icons/sidebar/ad-icon.svg",
  },
];
