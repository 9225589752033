import { Injectable } from "@angular/core";
import { UrlProvider } from "../../provider/url.provider";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class AppAdsService {
  private url: string = "AppAds/";

  constructor(private readonly urlProvider: UrlProvider, private http: HttpClient) {}

  public postFile(file: File, folderUploadDate: string) {
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("folderUploadDate", folderUploadDate);
    return this.urlProvider.post(`${this.url}Upload`, formData);
  }

  public getPublishersFolders(folderDate: string, fileType: string) {
    return this.urlProvider.get(`${this.url}GetPublishersFolders/${folderDate}/${fileType}`);
  }

  public getDownloadUrl(folderDate: string, folderName: string, fileType: string) {
    return this.urlProvider.get(`${this.url}GetDownloadUrl/${folderDate}/${folderName}/${fileType}`);
  }

  public getDownloadZipUrl(folderDate: string, fileType: string) {
    return this.urlProvider.get(`${this.url}GetDownloadZipUrl/${folderDate}/${fileType}`);
  }

  public getFoldersNames() {
    return this.urlProvider.get(`${this.url}GetFoldersNames`);
  }
}
